import React from "react";
import {
  ACCESS_DENIED_PATH,
  ROOT,
  ACCESS_TERMSOFSERVICE_PATH,
} from "../../constants/route.constant";

const appsRoute = [
  {
    key: "accessDenied",
    path: `${ACCESS_DENIED_PATH}`,
    component: React.lazy(() => import("../../views/access-denied")),
    authority: [],
  },
  {
    key: "home",
    path: `${ROOT}`,
    component: React.lazy(() => import("../../views/home")),
    authority: [],
  },
  {
    key: "terms",
    path: `${ACCESS_TERMSOFSERVICE_PATH}`,
    component: React.lazy(() => import("../../views/termsofService")),
    authority: [],
  },
];

export default appsRoute;
